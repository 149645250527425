import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'

import Layout from '../components/layout'

import Image from '../components/partials/image'

const TeamPage = ({ data, location }) => {
	const about = data.strapi.aboutPage
	const team = data.strapi.team

	const { state = {} } = location
	const { modal } = state

	return modal ? (
		<div className="bg-default absolute h-full w-full z-40">
			<div className="flex h-screen container max-w-full sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl xxl:max-w-xxl px-0 lg:px-40 xl:px-60 xxl:px-80 lg:py-40 overflow-y">
				<TeamCard member={team} close={true} />
			</div>
		</div>
	) : (
		<Layout page={about} metadata={about.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0 py-20 lg:py-40">
				<section className="py-20 lg:py-40">
					<TeamCard member={team} close={false} />
				</section>
			</div>
		</Layout>
	)
}

TeamPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			aboutPage: PropTypes.shape({
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
			team: PropTypes.shape({}).isRequired,
		}).isRequired,
	}).isRequired,
}

// Team Card Component
const TeamCard = ({ member, close }) => (
	<div className="bg-white grid grid-cols-1 lg:grid-cols-2 gap-30 m-auto">
		{/* Member Text Details */}
		<div className="flex flex-col justify-between p-30 lg:p-60">
			<div>
				<h2 className="h1 pb-5 lg:pb-10">{member.name}</h2>
				<h3 className="text-grey">{member.role}</h3>
				<div className="py-30 lg:py-60">{parse(member.content)}</div>
			</div>

			<div className="flex flex-row gap-30 h4">
				{member.instagram && (
					<ExternalLink link={member.instagram}>Instagram</ExternalLink>
				)}

				{member.twitter && (
					<ExternalLink link={member.twitter}>Twitter</ExternalLink>
				)}
			</div>
		</div>

		{/* Member Image */}
		<div className="grid relative">
			{/* Show Close Button only when close is true */}
			{close && <CloseButton />}

			<Image asset={member.slideshow[0]} />
		</div>
	</div>
)

TeamCard.propTypes = {
	member: PropTypes.shape({
		name: PropTypes.string.isRequired,
		role: PropTypes.string.isRequired,
		content: PropTypes.string.isRequired,
		instagram: PropTypes.string,
		twitter: PropTypes.string,
		slideshow: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
	}).isRequired,
	close: PropTypes.bool.isRequired,
}

// External Link Component
const ExternalLink = ({ link, children }) => (
	<a
		href={link}
		rel="noreferrer"
		target="_blank"
		className="underline hover:text-blue"
	>
		{children}
	</a>
)

ExternalLink.propTypes = {
	link: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}

// Close Button Component
const CloseButton = () => (
	<button
		onClick={() => {
			navigate(-1)
		}}
		className="fixed lg:absolute top-15 right-15 lg:top-30 lg:right-30 p-15 rounded-full focus:outline-none bg-white z-30 shadow-md"
	>
		<svg width="20" height="20" viewBox="0 0 20 20">
			<path
				fill="transparent"
				strokeWidth="2"
				stroke="#333333"
				d="M 2 18 L 18 2"
			/>
			<path
				fill="transparent"
				strokeWidth="2"
				stroke="#333333"
				d="M 2 2 L 18 18"
			/>
		</svg>
	</button>
)

export const query = graphql`
	query Team($id: ID!) {
		strapi {
			team(id: $id) {
				name
				role
				content
				instagram
				twitter
				slideshow {
					id
					url
					ext
					height
					width
					name
					imageFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: FULL_WIDTH
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
			}
			aboutPage {
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default TeamPage
